const hiddenColumnNames = ['nlosses', 'nwins', 'pl_pct', 'pl_nom'];

export const hiddenColumnsSettings = [
  ...hiddenColumnNames.flatMap(column => [
    `${column}_365`,
    `${column}_182`,
    `${column}_91`
  ]),
  'pl_pct',
  'nwins',
  'nlosses',
  'start',
  'end',
  'second_last_signal',
  'third_last_signal',
  'first_signal',
  'indicator_type',
  'sector',
  'indexes',
  'symbol_type',
];

export const stringColumnsSettings = [
  'security_name',
  'indexes',
  'position',
  'sector',
  'symbol_type',
  'start',
  'end',
  'first_signal',
  'config',
  'last_signal',
  'second_last_signal', 
  'third_last_signal', 
  'current_signal',
  'indicator_type'
];

export const filterColumnsSettings = [
  'indexes'
];

const periods = [365, 182, 91];
const baseIntegerColumns = ['nlosses', 'nwins', 'ntrades'];

export const integerColumnsSettings = [
  ...baseIntegerColumns,
  ...periods.flatMap(period => baseIntegerColumns.map(base => `${base}_${period}`))
];

const baseFloatColumns = ['pl_pct', 'pl_nom', 'outperf', 'hit']
const additionalFloatColumns = ['all_pl_nom', 'all_outperf'];

export const floatColumnsSettings = [
  ...baseFloatColumns,
  ...periods.flatMap(period => baseFloatColumns.map(base => `${base}_${period}`)),
  ...additionalFloatColumns
];

const columnNames = ['nlosses', 'nwins', 'ntrades', 'pl_pct', 'pl_nom', 'outperf', 'hit'];

const additionalColumnNames = [
  'last_signal', 'second_last_signal', 'third_last_signal', 'current_signal', 
  'all_pl_nom', 'all_outperf', 'indicator_type', 'start', 'end', 'first_signal', 
  'security_name', 'position', 'indexes', 'sector', 'symbol_type'
];

const scoreColumnNames = [
  "security_name",
  "Sector_Ranking_DAILY",
  "Sector_Ranking_WEEKLY",
  "Ranking_DAILY",
  "Ranking_WEEKLY",
  "Ranking_top_DAILY",
  "Ranking_top_WEEKLY",
  "Volume",
  "MACD_CROSS_DAILY",
  "MACD_CROSS_WEEKLY",
  "MACD_STD_DAILY",
  "MACD_STD_WEEKLY",
  "PUT_VOLA",
  "RS",
  "RS_MA",
  "Total score"
]

const obosColumnNames = [
  "security_name",
  "mov",
  "date",
  "diff",
  "lower_bound",
  "upper_bound",
  "entry",
  "entry_diff",
  "exit_date",
  "exit_pl"
]

const allColumns = [
  ...columnNames,
  ...periods.flatMap(period => columnNames.map(base => `${base}_${period}`)),
  ...additionalColumnNames,
  ...scoreColumnNames,
  ...obosColumnNames
];

export const tableColumnExtensionsSettings = allColumns.map(columnName => ({ columnName, width: '66' }));

tableColumnExtensionsSettings.push( 
  { columnName: 'config', width: 140 },
  { columnName: 'plus_5', width: 120 },
  { columnName: 'minus_5', width: 120 },
  { columnName: 'plus_10', width: 120 },
  { columnName: 'minus_10', width: 120 },
  { columnName: 'plus_20', width: 120 },
  { columnName: 'minus_20', width: 120 }
);